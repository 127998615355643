html.dark {
  background-color: rgb(15, 23, 42);
}

.grid-space {
  padding-bottom: 0.5rem;
}

.ho-ch {
  margin-bottom: 10rem;
  height: 10px;
}
