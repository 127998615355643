$fireColor: rgb(78, 213, 231);
$fireColorT: rgba(0, 68, 255, 0);
$dur: 1s;
$blur: 0.02em;
$fireRad: 3em;
$parts: 50;
$partSize: 5em;

body {
  margin: 0;
}
.fire {
  font-size: 4px;
  filter: blur(0.02em);
  -webkit-filter: blur(0.02em);
  margin: 11em auto 0 auto;
  position: inherit;
  width: 77em;
  height: 1em;
}
.particle {
  animation: rise 3s ease-out infinite;
  background-image: radial-gradient(
    rgb(78, 213, 231) 20%,
    rgba(0, 68, 255, 0) 70%
  );
  border-radius: 50%;
  animation-direction: reverse;
  mix-blend-mode: screen;
  opacity: 0;
  position: fixed;
  bottom: 0;
  width: 5em;
  height: 5em;
  // spread particles out in time and x-position to get desired effect
  @for $p from 1 through $parts {
    &:nth-of-type(#{$p}) {
      animation-delay: $dur * random();
      left: calc((100% - #{$partSize}) * #{($p - 1) / $parts});
    }
  }
}
@keyframes rise {
  from {
    opacity: 0;
    transform: translateY(0) scale(1);
  }
  25% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-10em) scale(0);
  }
}
